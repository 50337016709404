<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #4caf50" min-height="128">
    <MainModal
        :main="{ component: 'TariffGroup', title: 'Групи тарифу' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_group)"
        :modal="show_tariff_group_dialog"
        @updateItemModal="tariffGroupUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Група тарифу
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openTariffGroupCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нову групу тарифу</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-list class="pa-0">
      <v-divider></v-divider>
      <template v-for="(item, index) in tariffGroups">
        <v-list-item :key="`itm-${index}`" style="min-height: 36px" @click="onTariffGroupItemClick(item)">
          <v-list-item-avatar size="26">
            <v-icon size="20" class="success" color="white">
              mdi-timeline-text-outline
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="subtitle-1 py-1">
            <v-list-item-title style="font-size: .89rem">
              {{ item.tariff_group_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ `з ${formatDate(item.date_start)}` }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>
              {{
                `${item.date_end == null ? '' : 'по'} ${item.date_end == null ? '' : formatDate(item.date_end)}`
              }}
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="`vdv-${index}`"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import {formatDate} from "@/filters";
import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {FETCH_TARIFF_GROUP_BY_FLAT, FETCH_TARIFF_GROUP_SELECT} from "@/store/actions/tariff_group";
export default {
  name: "HWP_TariffGroups",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters({
      tariffGroupsAll: 'getTariffGroup',
      tariffGroupsActive: 'getTariffGroupActive',
      current_month: 'getCurrentMonth'
    }),
    tariffGroups() {
      return this.active ? this.tariffGroupsActive : this.tariffGroupsAll
    }
  },
  components: {
    MainModal
  },
  data() {
    return {
      selected_tariff_group: {},
      show_tariff_group_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchTariffGroups: FETCH_TARIFF_GROUP_BY_FLAT,
      fetchTariffGroupSelect: FETCH_TARIFF_GROUP_SELECT
    }),
    formatDate,
    tariffGroupUpdateItemModal(payload) {
      this.show_tariff_group_dialog = false
      this.selected_tariff_group = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openTariffGroupCreateDialog() {
      this.selected_tariff_group = {
        person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash,
        date_start: this.current_month
      }
      this.show_tariff_group_dialog = true
    },
    onTariffGroupItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))

      local_payload.person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      local_payload.tariff_group_type = payload.tariff_group_type_id
      local_payload.name = payload.tariff_group_name

      this.selected_tariff_group = local_payload
      this.show_tariff_group_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    this.fetchTariffGroups(person_hash)
      .then(res => {
        if (res) {
          this.fetchTariffGroupSelect(person_hash)
        }
      })
  }
}
</script>

<style scoped lang="scss">

</style>